<template>
    <CustomLoader v-if="pageLoading" height="500px" />

    <div class="py-20 personal-info-page" v-else>
        <div class="row col-12 mx-0">
            <div class="col-lg-4">
                <h2>{{ $t("Let us get to know you") }}</h2>
                <p>{{ $t("This helps in creating your personalised profile") }}</p>
            </div>
            <div class="col-lg-4 text-center">
                <img class="w-50" src="/media/logos/buying/blue-logo-buying-teams.svg" alt="">
            </div>
            <div class="col-lg-4 d-flex justify-content-end align-items-center">
                <p class="mb-0 me-3">{{ $t("Existing User ?") }}</p>
                <router-link to="/sign-in">{{ $t("Log in") }}</router-link>
            </div>
        </div>

        <div class="px-10 mt-10">
            <el-form
                class="setupForm"
                ref="personalInfoForm"
                :model="personalInfoForm"
                :rules="personalInfoRules">
                <el-form-item prop="first_name">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("First Name") }}</label>
                    <el-input
                        :placeholder="$t('Enter your First Name')"
                        v-model="personalInfoForm.first_name"></el-input>
                </el-form-item>
                <el-form-item prop="last_name">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Last Name") }}</label>
                    <el-input
                        :placeholder="$t('Enter your Last Name')"
                        v-model="personalInfoForm.last_name"></el-input>
                </el-form-item>
                <el-form-item prop="product_areas">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Product Areas") }}</label>
                    <el-select
                        multiple
                        collapse-tags
                        v-model="personalInfoForm.product_areas"
                        :placeholder="$t('Select which Bank Product Areas you work with')">
                        <el-option
                            v-for="(productArea, index) in allProductAreas"
                            :key="index"
                            :label="productArea"
                            :value="productArea"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="job_title">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Job Title") }}</label>

                    <el-input
                        :placeholder="$t('Please Input')"
                        v-model="personalInfoForm.job_title"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="country">
                    <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Country of Residence")
                        }}</label>
                    <el-select
                        v-model="personalInfoForm.country"
                        filterable
                        :placeholder="$t('Select your Country of your residence')">
                        <el-option
                            v-for="(item,index) in countries"
                            :key="index"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <button
                :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
                id="kt_sign_in_submit"
                class="btn btn-lg main-btn w-100 my-5 mt-20 d-block m-auto"
                @click="handleSave()">
                <span class="indicator-label"> {{ $t("Save") }} </span>

                <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
            </button>
            <div class="d-flex justify-content-center align-items-center mt-5">
                <a class="privacy-policy me-3 pe-3 border-end" :href="platformConfigs.platformLinks.Terms"
                   target="_blank">
                    {{ $t("Terms & Conditions") }}
                </a>
                <a class="privacy-policy" :href="platformConfigs.platformLinks.Privacy" target="_blank">
                    {{ $t("Privacy Policy") }}
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import store from "@/store";
import DashboardFilter from "@/buying-teams/shared-components/dashboard/DashboardFilter.vue";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { defineComponent } from "vue";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { DataService } from "@/core/services/DataService";
import { Mutations } from "@/store/enums/StoreEnums";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default defineComponent({
    name: "InvBusinessPersonalInfoSetup",
    components: {
        CustomLoader,
        DashboardFilter,
    },

    data() {
        return {
            personalInfoForm: {
                first_name: "",
                last_name: "",
                product_areas: [],
                job_title: "",
                // business_banks: [],
                country: ""
            },
            personalInfoRules: {
                first_name: [
                    { required: true, message: this.$t("Please input First name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                last_name: [
                    { required: true, message: this.$t("Please input Last name"), trigger: "change" }
                ],
                product_areas: [
                    { required: true, message: this.$t("Please select Product Area"), trigger: "change" }
                ],
                job_title: [
                    { required: true, message: this.$t("Please input Job Title"), trigger: "change" }
                ],
                country: [
                    { required: true, message: this.$t("Please select Country of Residence*"), trigger: "change" }
                ]

            },
            buttonIndicator: "off",
            pageLoading: true,
            platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
        };
    },

    computed: {
        allProductAreas() {
            return store.getters.allProductAreas;
        },
        banks() {
            return store.getters.allBanks;
        },
        countries() {
            return store.getters.allCountries;
        }
    },

    async mounted() {
        this.getStaticData();
    },

    methods: {
        getStaticData() {
            this.pageLoading = true;
            return Promise.all([
                store.dispatch("fetchStaticData", StaticDataEnum.PRODUCT_AREAS),
                store.dispatch("fetchStaticData", StaticDataEnum.BANKS),
                store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES)
            ]).finally(() => this.pageLoading = false);
        },
        handleSave() {
            this.$refs.personalInfoForm.validate((valid) => {
                if (valid) {
                    this.buttonIndicator = "on";
                    store.dispatch("setupBusinessUser", this.personalInfoForm)
                        .then(res => {
                            if (res.status === 200) {
                                store.commit("SAVE_BUSINESS_INFO", res.data.company);
                                store.commit(Mutations.SET_AUTH, res.data.company);
                                store.commit(Mutations.SET_USER, res.data.info);
                                DataService.storeUserFirstLogin();

                                window.location.href = "/dashboard";
                            }
                        })
                        .finally(_ => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        }
    }
});
</script>
<style lang="scss">
.personal-info-page {
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: #000000;
    }

    p {
        font-size: 16px;
        line-height: 29px;
        color: #434343;
        opacity: 0.6
    }

    .add-btn {
        background: rgba(67, 91, 244, 0.06) !important;
        border-radius: 56px;
        padding: 10px 25px !important;
        border: none;
    }

    .filterable-select {
        .el-select__tags {
            position: revert;
            transform: revert;

            > span {
                position: absolute;
                bottom: -36px;
            }

            .el-select__input {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100% !important;
                z-index: 9;
            }
        }
    }
}

.setupForm.setupForm-select-bank {
    flex-wrap: nowrap;

    @media (max-width: 1200px) {
        .el-form-item {
            width: 43%;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        border-top: 1px solid #eaeaea;
        .el-form-item {
            width: 100%;
        }
        .mt-10.cursor-pointer {
            margin-top: 0 !important;
        }
    }
}
</style>
